import { Injectable } from '@angular/core';


import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError, withLatestFrom, map } from 'rxjs/operators';

import * as fromStore from '../store';
import * as fromRoot from '../../store';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ContentBlocksGuard  {
  constructor(private store: Store<fromStore.ContentBlocksState>) {}

  canActivate(): Observable<boolean> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    return this.store.select(fromStore.getSelectedContentBlocksLoaded).pipe(
      withLatestFrom(this.store.select(fromRoot.getRouterState)),
      tap(([loaded, router]) => {
        if (!loaded) {
          const pageId = router.state.params?.pageId || router.state.data?.pageId;
          this.store.dispatch(fromStore.LoadContentBlocks({ pageId }));
        }
      }),
      map(([loaded, router]) => loaded),
      filter(loaded => loaded),
      take(1)
    );
  }
}
